
















































import Vue from "vue";
import { t } from "#root/lib/utils";
import SessionModule from "#root/store/session";
import AppModule from "#root/store/app";
const UserProfileComponent = () =>
  import("#root/components/menu/UserProfile.vue");
const LanguageButton = () =>
  import("#root/components/generic/LanguageButton.vue");
const WorldsPanel = () => import("#root/components/menu/WorldsPanel.vue");

import { UserProfile } from "#mws/interfaces";
import { LocaleKeys } from "#mws/data";
import Store from "#root/store/store";

export default Vue.extend({
  name: "Menu",
  components: {
    UserProfile: UserProfileComponent,
    LanguageButton,
    WorldsPanel,
  },
  data: () => ({
    lk: LocaleKeys,
  }),
  computed: {
    profile(): UserProfile | undefined {
      return SessionModule.profile;
    },
  },
  methods: {
    t: t,
    logout() {
      AppModule.showAlert({
        title: { key: LocaleKeys.alert.logout },
        message: { key: LocaleKeys.alert.logout },
        buttons: [
          {
            text: { key: LocaleKeys.alert.cancel },
            color: "primary",
            action: AppModule.hideAlert,
          },
          {
            text: { key: LocaleKeys.alert.logout },
            color: "error",
            action: () => {
              Store.logout();
              AppModule.loadingAlert();
              this.$router.push("/login");
            },
          },
        ],
      });
    },
  },
});
